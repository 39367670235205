import {
  videographyImg,
  socialMediaManagementImg,
  brandingImg,
  webDesignImg,
  advertisementsImg,
  naniFcLogo,
  fitbodegaLogo,
  tstLogo,
  vmcLogo,
  genesisImg,
  cartocciImg,
  studyImg,
  taurusImg,
  visaImg,
  kengo1Video,
  showroomVideo,
  contentCreationImg,
  streetBallLogo,
  arshiaEsmaeiliLogo,
  artbuildLogo,
  genesisBoxingLogo,
  samamiralaeiLogo,
  cfrLogo,
  gscLogo,
  wvtcLogo,
  secondGallery2Img,
  secondGallery5Img,
  secondGallery6Img,
  secondGallery8Img,
  secondGallery9Img,
  thirdGallery1Img,
  thirdGallery2Img,
  thirdGallery3Img,
  thirdGallery4Img,
  thirdGallery6Img,
  forthGallery1Img,
  forthGallery3Img,
  forthGallery4Img,
  forthGallery8Img,
  forthGallery12Img,
  kengo3Img,
  cindysLogo,
  genesisInfiniteImg,
  kasraaz1Img,
  kasraz2Img,
  taurusInfiniteImg,
  kengoInfiniteImg,
  kasrazLogo,
  katwelLogo,
  sturdybeeLogo,
  velaHomesLogo,
  BCCLogo,
} from '../utils';

import {
  HomeIcon,
  WrenchScrewdriverIcon,
  PhoneIcon,
  VideoCameraIcon,
  SparklesIcon,
  CommandLineIcon,
} from '@heroicons/react/24/solid';

export const navLists = [
  {
    id: 1,
    label: 'Home',
    href: '/',
  },
  {
    id: 2,
    label: 'Services',
    href: '/services',
  },
  {
    id: 6,
    label: 'Software',
    href: '/web-development',
  },
  {
    id: 3,
    label: 'Visual Production',
    href: '/visual-production',
  },
  {
    id: 4,
    label: 'Contact',
    href: '/contact',
  },
  {
    id: 5,
    label: 'About',
    href: '/about',
  },
  // {
  //   id: 7,
  //   label: 'Visual Production',
  //   href: '/production/visual-production',
  // },
];

export const hamburgerLists = [
  {
    id: 1,
    label: 'Home',
    href: '/',
    Icon: HomeIcon,
  },
  {
    id: 2,
    label: 'Services',
    href: '/services',
    Icon: WrenchScrewdriverIcon,
  },
  {
    id: 6,
    label: 'Software',
    href: '/web-development',
    Icon: CommandLineIcon,
  },
  {
    id: 3,
    label: 'Visual Production',
    href: '/visual-production',
    Icon: VideoCameraIcon,
  },
  {
    id: 4,
    label: 'Contact',
    href: '/contact',
    Icon: PhoneIcon,
  },
  {
    id: 5,
    label: 'About',
    href: '/about',
    Icon: SparklesIcon,
  },
  // {
  //   id: 7,
  //   label: 'Visual Production',
  //   href: '/production/visual-production',
  // },
];

export const hightlightsSlides = [
  {
    id: 1,
    textLists: ['Vela Homes.', 'Documenting Every Step of The Journey'],
    video: '/assets/videos/velahomes-forming.mp4',
    videoDuration: 8,
  },
  {
    id: 2,
    textLists: [
      'Taurus Fitness Club.',
      'Inspirational Fitness Journey Advertisement.',
    ],
    video: '/assets/videos/taurus-commercial.mp4',
    videoDuration: 10,
  },
  {
    id: 3,
    textLists: [
      'Canadian Flooring and Renovations.',
      'Commercial Construction Development.',
    ],
    video: showroomVideo,
    videoDuration: 10,
  },
  {
    id: 4,
    textLists: ['Kengo Kuma By Alberni.', 'Dynamic Real Estate Showcases.'],
    video: kengo1Video,
    videoDuration: 4,
  },
  {
    id: 5,
    textLists: ['Fitbodega Vancouver FC.', 'TV News Introduction.'],
    video: '/assets/videos/fitbodega-tv-news.mp4',
    videoDuration: 6,
  },
  {
    id: 6,
    textLists: ['Vela Homes.', 'Capturing the Path for Visionary Builds.'],
    video: '/assets/videos/velahomes-demolishing.mp4',
    videoDuration: 5,
  },
];

export const servicesSection = [
  {
    id: 1,
    title: 'Branding',
    desc: 'Your brand deserves to reflect the essence of who you are. We don’t just create logos; we build comprehensive brand identities that are as unique and dynamic as your vision, resonating with your audience and leaving a lasting impression.',
    icon: brandingImg,
  },
  {
    id: 2,
    title: 'Web Development',
    desc: 'We craft websites that seamlessly blend aesthetics with functionality, ensuring each visit feels inviting and intuitive. Your digital presence should be more than just visually appealing—it should be an extension of your brand that engages and inspires.',
    icon: webDesignImg,
  },
  {
    id: 3,
    title: 'Content Creation',
    desc: 'We transform your ideas into impactful stories that speak directly to your audience. Our approach to content creation goes beyond just words on a page; it’s about weaving narratives that captivate, inform, and drive meaningful results.',
    icon: contentCreationImg,
  },
  {
    id: 4,
    title: 'Advertising',
    desc: 'Our targeted advertising connects your brand with the right audience, making each dollar count. We create campaigns that deliver real impact, ensuring your message reaches those who matter most and propels your brand forward.',
    icon: advertisementsImg,
  },
  {
    id: 5,
    title: 'Videography & Photography',
    desc: 'Our high-resolution photography and cinematic videos breathe life into your brand. Every frame is crafted to tell your story with authenticity and energy, creating powerful visuals that engage and leave a memorable impact.',
    icon: videographyImg,
  },
  {
    id: 6,
    title: 'Social Media Management',
    desc: 'We create social media content that resonates and builds connections. Each post is thoughtfully designed to strengthen your community and amplify your brand’s voice, making your online presence as vibrant and engaging as your vision.',
    icon: socialMediaManagementImg,
  },
];

export const galleryImg = [
  {
    id: 1,
    srcImg: kengo3Img,
  },
  {
    id: 2,
    srcImg: '../../assets/images/gsc-1.png',
  },
  {
    id: 3,
    srcImg: '../../assets/images/loraine-2.jpg',
  },
  {
    id: 4,
    srcImg: '../../assets/images/kasraz-3.png',
  },
];

export const footerLinks = [
  'Privacy Policy',
  'Terms of Use',
  'Sales Policy',
  'Legal',
  'Site Map',
];

export const clientImg = [
  {
    id: 1,
    srcImg: fitbodegaLogo,
  },
  {
    id: 2,
    srcImg: visaImg,
  },
  {
    id: 3,
    srcImg: cartocciImg,
  },
  {
    id: 4,
    srcImg: naniFcLogo,
  },
  {
    id: 5,
    srcImg: samamiralaeiLogo,
  },
  {
    id: 6,
    srcImg: taurusImg,
  },
  {
    id: 7,
    srcImg: genesisBoxingLogo,
  },
  {
    id: 8,
    srcImg: genesisImg,
  },
  {
    id: 9,
    srcImg: artbuildLogo,
  },
  {
    id: 10,
    srcImg: kasrazLogo,
  },
  {
    id: 11,
    srcImg: BCCLogo,
  },
  {
    id: 12,
    srcImg: katwelLogo,
  },
];

export const clientImg2 = [
  {
    id: 1,
    srcImg: arshiaEsmaeiliLogo,
  },
  {
    id: 2,
    srcImg: tstLogo,
  },
  {
    id: 3,
    srcImg: studyImg,
  },
  {
    id: 4,
    srcImg: cfrLogo,
  },
  {
    id: 5,
    srcImg: streetBallLogo,
  },
  {
    id: 6,
    srcImg: gscLogo,
  },
  {
    id: 7,
    srcImg: cindysLogo,
  },
  {
    id: 8,
    srcImg: vmcLogo,
  },

  {
    id: 9,
    srcImg: wvtcLogo,
  },
  {
    id: 10,
    srcImg: sturdybeeLogo,
  },
  {
    id: 11,
    srcImg: velaHomesLogo,
  },
];

export const youtubeEmbedIds = [
  {
    id: 1,
    embedId: 'P2vkIx5royE',
  },
  {
    id: 2,
    embedId: '4W4UqdZYEKs',
  },
  {
    id: 3,
    embedId: 'y2g4CDlOdaE',
  },
  {
    id: 4,
    embedId: 'rs1W8kfQc3U',
  },
  {
    id: 5,
    embedId: '3QH6Mb01Pys',
  },
  {
    id: 6,
    embedId: 'YSSWOh-tGDY',
  },
  {
    id: 7,
    embedId: 'OO5Q69P96_s',
  },
  {
    id: 8,
    embedId: 'uhQRvUxN4CQ',
  },
];

export const secondGalleryImg = [
  {
    id: 1,
    srcImg: secondGallery2Img,
  },
  {
    id: 2,
    srcImg: secondGallery6Img,
  },
  {
    id: 3,
    srcImg: secondGallery8Img,
  },
  {
    id: 4,
    srcImg: secondGallery5Img,
  },
  // {
  //   id: 5,
  //   srcImg: secondGallery1Img,
  // },
  // {
  //   id: 6,
  //   srcImg: secondGallery3Img,
  // },
  // {
  //   id: 7,
  //   srcImg: secondGallery7Img,
  // },
  // {
  //   id: 8,
  //   srcImg: secondGallery4Img,
  // },
];

export const thirdGalleryImg = [
  {
    id: 1,
    srcImg: thirdGallery1Img,
  },
  {
    id: 2,
    srcImg: thirdGallery4Img,
  },
  {
    id: 3,
    srcImg: secondGallery9Img,
  },
  {
    id: 4,
    srcImg: thirdGallery2Img,
  },
  {
    id: 5,
    srcImg: thirdGallery6Img,
  },
  {
    id: 6,
    srcImg: thirdGallery3Img,
  },
];

export const forthGalleryImg = [
  // {
  //   id: 1,
  //   srcImg: forthGallery1Img,
  // },
  // {
  //   id: 2,
  //   srcImg: forthGallery2Img,
  // },
  {
    id: 3,
    srcImg: forthGallery3Img,
  },
  {
    id: 4,
    srcImg: forthGallery4Img,
  },
  // {
  //   id: 5,
  //   srcImg: forthGallery5Img,
  // },
  // {
  //   id: 6,
  //   srcImg: forthGallery6Img,
  // },
  // {
  //   id: 7,
  //   srcImg: forthGallery7Img,
  // },
  {
    id: 8,
    srcImg: forthGallery8Img,
  },
  // {
  //   id: 9,
  //   srcImg: forthGallery9Img,
  // },
  // {
  //   id: 10,
  //   srcImg: forthGallery10Img,
  // },
  // {
  //   id: 11,
  //   srcImg: forthGallery11Img,
  // },
  {
    id: 12,
    srcImg: forthGallery12Img,
  },
];

export const stats = [
  {
    id: 1,
    name: '3D Tours – Photography/Videography – Aerial',
    value: 'Real Estate',
    detailContent:
      'Showcase properties with high quality visuals, drone footage, and 3D tours, paired with custom websites and strategic content.',
    content: 'perseus on top',
  },
  {
    id: 2,
    name: 'Aerial – Photography –  Videography',
    value: 'Construction',
    detailContent:
      'Present projects with detailed visuals that capture progress and craftsmanship. We create content that builds trust and sparks interest in your work.',
    content: 'perseus on top',
  },
  {
    id: 3,
    name: 'Branded Videos – Photography – Informational',
    value: 'Fitness & Sports',
    detailContent:
      'Inspire audiences with high-energy videos and engaging photography. We position your brand as a leader, building community and boosting participation.',
    content: 'perseus on top',
  },
  {
    id: 4,
    name: 'Highlights – Promotional Videos – Photography',
    value: 'Event & Seminar',
    detailContent:
      'Drive attendance and excitement with impactful event visuals. From event-day highlights to post-event content, we make sure your event is unforgettable.',
    content: 'perseus on top',
  },
  {
    id: 5,
    name: 'Testimonials – Educational Videos – Photography',
    value: 'Healthcare & Beauty',
    detailContent:
      'Connect with patients and clients through authentic content that builds trust. Our strategies emphasize your expertise and showcase your care.',
    content: 'perseus on top',
  },
  {
    id: 6,
    name: 'Videography – Photography – Storytelling',
    value: 'Food & Beverage',
    detailContent:
      'Connect with patients and clients through authentic content that builds trust. Our strategies emphasize your expertise and showcase your care.',
    content: 'perseus on top',
  },
  {
    id: 7,
    name: 'Web Design – Store Management – Social Media',
    value: 'E-commerce & Product',
    detailContent:
      'Showcase your products with eye catching visuals and clear demos. Our media solutions attract attention and drive conversions effectively.',
    content: '',
  },
  {
    id: 8,
    name: 'Branding – Web Development – Media',
    value: 'Tech & Startup',
    detailContent:
      'Our educational content makes learning engaging and accessible, creating connections that support lifelong learners.',
    content: '',
  },
  {
    id: 9,
    name: 'Content – Educational – Lifestyle',
    value: 'Personal Branding',
    detailContent:
      'Create a strong, authentic presence with captivating visuals and content that tells your unique story and connects with your audience.',
    content: '',
  },
];

export const team = [
  {
    id: 1,
    img: 'https://media.licdn.com/dms/image/D5603AQGXjld1-leEQg/profile-displayphoto-shrink_100_100/0/1696183512424?e=1724889600&v=beta&t=hO3edY-DMPGq9N9yaY68qlMjeHa1KuWfcrn8H4nBKmo',
    title: 'CEO / Founder',
    name: 'Aryan Ghasemi',
  },
  {
    id: 2,
    img: 'https://media.licdn.com/dms/image/D5603AQGXjld1-leEQg/profile-displayphoto-shrink_100_100/0/1696183512424?e=1724889600&v=beta&t=hO3edY-DMPGq9N9yaY68qlMjeHa1KuWfcrn8H4nBKmo',
    title: 'CEO / Founder',
    name: 'Aryan Ghasemi',
  },
  {
    id: 3,
    img: 'https://media.licdn.com/dms/image/D5603AQGXjld1-leEQg/profile-displayphoto-shrink_100_100/0/1696183512424?e=1724889600&v=beta&t=hO3edY-DMPGq9N9yaY68qlMjeHa1KuWfcrn8H4nBKmo',
    title: 'CEO / Founder',
    name: 'Aryan Ghasemi',
  },
  {
    id: 4,
    img: 'https://media.licdn.com/dms/image/D5603AQExyaUztgnL7A/profile-displayphoto-shrink_400_400/0/1692111463402?e=1724889600&v=beta&t=MyiwFuNVwXXxyq4_WTIy0p-MQxJG_ED5emftBquR2sM',
    title: 'CTO / Software Engineer',
    name: 'Saman Hoseinpour',
  },
  {
    id: 5,
    img: 'https://media.licdn.com/dms/image/D5603AQExyaUztgnL7A/profile-displayphoto-shrink_400_400/0/1692111463402?e=1724889600&v=beta&t=MyiwFuNVwXXxyq4_WTIy0p-MQxJG_ED5emftBquR2sM',
    title: 'CTO / Software Engineer',
    name: 'Saman Hoseinpour',
  },
  {
    id: 6,
    img: 'https://media.licdn.com/dms/image/D5603AQGXjld1-leEQg/profile-displayphoto-shrink_100_100/0/1696183512424?e=1724889600&v=beta&t=hO3edY-DMPGq9N9yaY68qlMjeHa1KuWfcrn8H4nBKmo',
    title: 'CEO / Founder',
    name: 'Aryan Ghasemi',
  },
  {
    id: 7,
    img: 'https://media.licdn.com/dms/image/D5603AQExyaUztgnL7A/profile-displayphoto-shrink_400_400/0/1692111463402?e=1724889600&v=beta&t=MyiwFuNVwXXxyq4_WTIy0p-MQxJG_ED5emftBquR2sM',
    title: 'CTO / Software Engineer',
    name: 'Saman Hoseinpour',
  },
  {
    id: 8,
    img: 'https://media.licdn.com/dms/image/D5603AQGXjld1-leEQg/profile-displayphoto-shrink_100_100/0/1696183512424?e=1724889600&v=beta&t=hO3edY-DMPGq9N9yaY68qlMjeHa1KuWfcrn8H4nBKmo',
    title: 'CTO / Software Engineer',
    name: 'Saman Hoseinpour',
  },
];

export const servicesCarousel = [
  {
    id: 1,
    title: 'Videography',
    img: '../../assets/images/services/FX3.png',
    width: 330,
  },
  {
    id: 2,
    title: 'Website Development',
    img: '../../assets/images/services/websiteDesign.png',
    width: 500,
  },
  {
    id: 3,
    title: 'Photography',
    img: '../../assets/images/services/photographyFX3.png',
    width: 225,
  },
  {
    id: 4,
    title: 'Content Creation',
    img: '../../assets/images/services/contentCreation.png',
    width: 500,
  },
  {
    id: 5,
    title: 'Branding',
    img: '../../assets/images/services/branding.png',
    width: 230,
  },

  {
    id: 6,
    title: 'Advertising',
    img: '../../assets/images/services/ads.png',
    width: 500,
  },
  {
    id: 7,
    title: 'Social Media Management',
    img: '../../assets/images/services/perseus-phone.png',
    width: 500,
  },

  {
    id: 8,
    title: 'Aerial Production',
    img: '../../assets/images/services/drone-dji.png',
    width: 400,
  },
  {
    id: 9,
    title: '3D Modeling',
    img: '../../assets/images/services/3d-modeling.png',
    width: 350,
  },
];

export const infiniteCarouselImgs = [
  forthGallery4Img,
  thirdGallery1Img,
  secondGallery2Img,
  thirdGallery4Img,
  kasraaz1Img,
  taurusInfiniteImg,
  forthGallery1Img,
  genesisInfiniteImg,
  kengoInfiniteImg,
  forthGallery3Img,
  secondGallery8Img,
  kasraz2Img,
];

export const heroSliderImgs = [
  '../../assets/images/visual-production/heroSlider-1.jpg',
  '../../assets/images/loraine-2.jpg',
  '../../assets/images/genesis1.jpg',
  '../../assets/images/visual-production/heroSlider-2.jpg',
  '../../assets/images/velahomes-2.jpg',
  '../../assets/images/visual-production/heroSlider-3.jpg',
];

export const webServicesData = [
  {
    id: 1,
    name: 'Grammarly – BuzzSumo',
    value: 'Content Strategy',
    content:
      'Create engaging, SEO-optimized copy that reflects your brand’s voice and drives user interaction. Our strategy ensures content that informs, inspires, and boosts search performance.',
  },
  {
    id: 2,
    name: 'ManageWP – Sucuri –  Manual',
    value: 'Maintenance',
    content:
      'Keep your site secure, up-to-date, and efficient with regular updates, performance monitoring, and content management. We handle everything so your site stays fresh and reliable.',
  },
  {
    id: 3,
    name: 'Ahrefs – PageSpeed Insights (Google)',
    value: 'SEO - Optimization',
    content:
      'Boost your site’s visibility and user retention with on-page SEO, fast load times, and responsive design. We optimize your site for search engines and user experience.',
  },
  {
    id: 4,
    name: 'Hotjar – UserTesting',
    value: 'User Experience',
    content:
      'Prioritize seamless navigation and engagement with user-centric layouts that lead to higher satisfaction and conversions. Our strategic designs make finding what visitors need effortless.',
  },
  {
    id: 5,
    name: 'Shopify - WooCommerce',
    value: 'E-commerce',
    content:
      'Take the stress out of running your online store. We manage product updates, inventory, and promotions, ensuring a smooth, engaging experience that boosts customer satisfaction and sales.',
  },
  {
    id: 6,
    name: 'Google Analytics – Bing Console',
    value: 'Analytics',
    content: `Track your website's performance with in-depth analytics and insights to understand user behavior, measure success, and refine your strategy.`,
  },
  {
    id: 7,
    name: 'Figma – Adobe XD',
    value: 'User Interface (UI)',
    content:
      'Enhance your brand’s identity with visually striking, cohesive interfaces. We design interactive elements, typography, and color schemes that captivate users and reinforce brand recognition.',
  },
  {
    id: 8,
    name: 'Postman – Zapier',
    value: 'APIs',
    content:
      'Enhance your brand’s identity with visually striking, cohesive interfaces. We design interactive elements, typography, and color schemes that captivate users and reinforce brand recognition.',
  },
];

export const benchmarkData = [
  {
    title: 'Speed and Performance',
    nextJSData: {
      multiplier: '17x Faster',
      value: 90,
    },
    reactData: {
      multiplier: '12x Faster',
      value: 75,
    },
    wordpressData: {
      multiplier: '9x Faster',
      value: 60,
    },
    wixData: {
      multiplier: '7x Faster',
      value: 50,
    },
  },
  {
    title: 'Scalability and Flexibility',
    nextJSData: {
      multiplier: '22 Scalable',
      value: 95,
    },
    reactData: {
      multiplier: '18x Scalable',
      value: 80,
    },
    wordpressData: {
      multiplier: '15x Scalable',
      value: 70,
    },
    wixData: {
      multiplier: '10x Scalable',
      value: 40,
    },
  },
  {
    title: 'Customization and Control',
    nextJSData: {
      multiplier: '15x Adaptable',
      value: 90,
    },
    reactData: {
      multiplier: '14x Adaptable',
      value: 85,
    },
    wordpressData: {
      multiplier: '10x Adaptable',
      value: 65,
    },
    wixData: {
      multiplier: '5x Adaptable',
      value: 45,
    },
  },
  {
    title: 'Client-Friendliness and Maintenance',
    nextJSData: {
      multiplier: '20x UI Friendly',
      value: 95,
    },
    reactData: {
      multiplier: '16x UI Friendly',
      value: 85,
    },
    wordpressData: {
      multiplier: '10x UI Friendly',
      value: 60,
    },
    wixData: {
      multiplier: '8x UI Friendly',
      value: 50,
    },
  },
  {
    title: 'SEO and Marketing Features',
    nextJSData: {
      multiplier: '15x Optimized',
      value: 88,
    },
    reactData: {
      multiplier: '12x Optimized',
      value: 80,
    },
    wordpressData: {
      multiplier: '10x Optimized',
      value: 70,
    },
    wixData: {
      multiplier: '6x Optimized',
      value: 50,
    },
  },
  {
    title: 'Integration and Third-Party Support',
    nextJSData: {
      multiplier: '18x Integrable',
      value: 95,
    },
    reactData: {
      multiplier: '16x Integrable',
      value: 90,
    },
    wordpressData: {
      multiplier: '15x Integrable',
      value: 85,
    },
    wixData: {
      multiplier: '10x Integrable',
      value: 60,
    },
  },
];
